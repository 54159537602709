import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M2210 4364 c-358 -46 -735 -202 -1035 -427 -165 -125 -394 -374 -520
-567 -112 -172 -219 -421 -276 -645 -75 -292 -79 -678 -9 -997 116 -536 453
-1027 915 -1337 323 -216 664 -332 1059 -362 555 -41 1118 139 1543 495 416
347 676 805 769 1351 24 140 24 478 1 635 -38 246 -98 438 -207 658 -325 657
-948 1103 -1668 1196 -150 19 -423 19 -572 0z m585 -60 c878 -120 1604 -804
1780 -1677 174 -866 -189 -1725 -935 -2209 -238 -155 -533 -266 -835 -314
-167 -27 -468 -24 -643 5 -597 101 -1104 429 -1432 927 -138 209 -257 491
-305 721 -149 716 63 1431 575 1943 371 372 853 590 1365 619 153 9 291 4 430
-15z"/>
<path d="M2210 4194 c-905 -136 -1604 -858 -1711 -1768 -15 -128 -6 -431 16
-556 69 -385 226 -712 480 -1001 498 -566 1277 -803 2010 -612 479 124 909
436 1185 858 215 330 336 767 316 1150 -17 332 -86 595 -231 872 -297 570
-854 964 -1496 1057 -134 20 -437 20 -569 0z m569 -129 c208 -30 464 -118 688
-235 246 -129 446 -312 606 -555 77 -117 114 -192 182 -365 102 -264 135 -424
139 -685 3 -172 3 -173 -3 -40 l-7 140 -2 -140 c-5 -293 -61 -538 -182 -792
-242 -508 -688 -872 -1242 -1013 -214 -55 -542 -68 -763 -30 -513 87 -961 376
-1252 806 -94 138 -196 361 -241 524 -30 107 -62 295 -61 354 1 40 4 30 15
-49 57 -427 238 -798 534 -1096 164 -164 314 -270 531 -373 258 -123 507 -177
804 -174 l160 1 -200 7 c-227 8 -339 27 -526 90 -325 108 -670 352 -877 620
-88 114 -85 110 -79 110 2 0 30 -34 61 -75 31 -42 61 -73 66 -70 5 3 12 0 16
-6 5 -8 3 -9 -7 -4 -8 5 -11 4 -6 -1 5 -5 16 -9 26 -9 10 0 43 -28 75 -62 117
-126 330 -282 496 -361 177 -85 407 -152 580 -168 107 -10 83 3 -41 21 -222
33 -379 82 -549 172 -304 161 -557 399 -720 675 -19 31 -38 54 -42 52 -5 -3
-8 -2 -7 3 4 16 -27 73 -36 66 -4 -5 -5 -3 -2 3 4 7 0 34 -8 60 -18 61 -18 65
1 34 l15 -25 -5 33 c-3 18 -2 31 2 28 4 -2 14 9 21 26 19 43 66 68 128 68 66
0 109 -34 123 -95 17 -72 13 -74 -115 -77 l-113 -3 14 -40 c17 -52 37 -65 96
-65 35 0 54 6 69 21 11 11 27 18 35 15 21 -8 2 -42 -34 -60 -32 -16 -112 -24
-131 -12 -17 11 -2 -19 52 -99 79 -117 172 -224 282 -325 27 -25 4 2 -51 60
-105 111 -168 188 -228 282 -54 85 -44 81 20 -8 78 -109 112 -150 197 -236
100 -101 213 -191 228 -182 7 4 9 3 6 -3 -13 -21 179 -136 339 -203 215 -90
469 -131 699 -114 l120 9 -170 6 c-93 4 -190 11 -215 16 -35 8 -20 9 70 5 147
-6 398 12 518 38 242 51 465 154 637 293 67 54 255 239 255 251 0 5 8 19 17
30 10 12 -32 -27 -92 -86 -133 -128 -157 -149 -260 -220 -163 -112 -334 -189
-530 -238 -278 -70 -616 -65 -888 15 -90 26 -63 26 45 0 292 -71 641 -56 931
39 333 109 637 341 836 639 106 158 208 405 248 604 28 136 25 462 -5 600 -89
410 -329 763 -677 997 -92 63 -268 147 -381 185 -327 107 -711 98 -1039 -24
-175 -65 -350 -173 -530 -324 -60 -51 -118 -97 -128 -102 -17 -10 -17 -9 -3
19 8 16 28 45 43 63 l27 34 -38 -35 c-70 -64 -246 -285 -318 -400 -20 -30 -57
-98 -83 -150 -26 -52 -51 -97 -55 -100 -4 -3 -17 -36 -30 -74 -18 -56 -24 -66
-32 -52 -6 10 -7 37 -4 61 7 44 -7 72 -17 34 -8 -30 -28 -13 -22 19 2 15 -2 7
-10 -18 -9 -25 -27 -106 -40 -180 -14 -74 -30 -139 -35 -144 -6 -6 -10 -26
-10 -45 0 -22 -3 -31 -8 -24 -9 14 -8 27 7 160 16 137 37 237 72 340 17 51 27
98 23 104 -4 7 -3 9 4 5 13 -8 26 29 16 45 -5 8 -3 9 6 4 10 -6 12 -4 7 8 -4
10 -1 17 5 17 6 0 9 3 6 6 -3 3 20 57 52 120 32 63 52 110 45 104 -39 -32
-205 -406 -190 -429 3 -6 -4 -43 -15 -83 -11 -40 -29 -138 -41 -218 -26 -184
-43 -207 -37 -50 l5 115 -18 -89 c-10 -48 -21 -91 -25 -95 -15 -14 5 267 25
349 55 218 150 437 262 600 113 166 372 415 549 529 238 152 465 235 825 300
85 15 397 13 514 -4z m-934 -740 c82 -21 162 -77 206 -145 20 -30 129 -264
243 -520 218 -489 222 -498 230 -489 3 3 99 235 212 515 113 280 219 542 236
582 l30 72 217 0 c155 0 220 -3 229 -12 7 -7 12 -27 12 -45 0 -35 -3 -37 -89
-47 -55 -6 -92 -33 -108 -77 -17 -48 -17 -1120 0 -1168 16 -44 53 -71 108 -77
86 -10 89 -12 89 -47 0 -18 -5 -38 -12 -45 -17 -17 -619 -17 -636 0 -7 7 -12
27 -12 45 0 35 3 37 89 47 24 2 56 13 70 24 50 37 51 54 49 609 l-3 515 -244
-603 c-134 -332 -250 -614 -258 -627 -11 -19 -21 -23 -51 -20 l-37 3 -272 614
c-149 337 -274 616 -278 619 -3 3 -5 -232 -3 -524 3 -494 4 -532 21 -558 22
-32 72 -56 121 -56 19 0 41 -3 50 -6 19 -7 22 -64 4 -82 -13 -13 -484 -18
-504 -4 -18 12 -26 50 -14 72 8 16 21 20 56 20 63 0 109 24 131 68 17 35 18
75 18 602 0 542 -1 566 -19 597 -21 34 -64 56 -132 69 -21 3 -45 11 -51 17
-19 14 -16 64 5 77 23 15 219 5 297 -15z m-1098 -607 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m3640 -765 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2
-10 2 -26 0 -35z m-10 -70 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0
-30z m-10 -55 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-13 -72
c-3 -21 -8 -35 -11 -32 -2 2 -1 20 3 40 3 21 8 35 11 32 2 -2 1 -20 -3 -40z
m-2885 -30 c52 -27 68 -113 32 -173 -11 -17 -46 -50 -78 -74 -82 -59 -113 -90
-113 -111 0 -16 10 -18 105 -18 98 0 105 -1 105 -20 0 -19 -7 -20 -130 -20
l-130 0 0 39 c0 49 21 76 110 140 81 59 104 90 104 139 0 44 -32 72 -83 72
-39 0 -101 -32 -101 -52 0 -8 -6 -6 -17 5 -53 53 114 115 196 73z m361 -131
l0 -145 35 0 c28 0 35 -4 35 -20 0 -16 -7 -20 -35 -20 l-35 0 0 -50 c0 -47 -2
-50 -25 -50 -23 0 -25 3 -25 50 l0 50 -100 0 c-93 0 -100 1 -100 20 0 11 43
85 96 165 87 132 98 145 125 145 l29 0 0 -145z m370 118 c-1 -16 -36 -111 -79
-213 -64 -149 -83 -186 -100 -188 -12 -2 -21 -1 -21 3 0 3 34 86 75 184 41 99
75 184 75 190 0 7 -36 11 -105 11 -98 0 -105 1 -105 20 0 19 7 20 130 20 l130
0 0 -27z m307 12 c53 -16 95 -54 115 -104 5 -14 10 -57 11 -96 1 -153 -74
-215 -260 -215 l-83 0 0 215 0 215 83 0 c46 0 106 -7 134 -15z m481 -192 c40
-115 72 -212 72 -216 0 -5 -10 -7 -22 -5 -18 2 -27 16 -43 61 l-20 58 -94 -3
-94 -3 -20 -57 c-16 -50 -22 -58 -44 -58 -19 0 -24 4 -19 16 4 9 36 101 71
203 36 102 67 192 70 199 2 6 19 12 38 12 l33 0 72 -207z m432 187 c0 -18 -7
-20 -65 -20 l-65 0 0 -195 0 -195 -25 0 -25 0 0 195 0 195 -65 0 c-58 0 -65 2
-65 20 0 19 7 20 155 20 148 0 155 -1 155 -20z m328 -187 c40 -115 72 -211 72
-215 0 -5 -11 -8 -23 -8 -20 0 -27 9 -42 60 l-18 61 -95 -3 -95 -3 -20 -57
c-16 -50 -22 -58 -44 -58 -20 0 -24 4 -18 18 4 9 36 100 71 202 35 102 66 191
69 198 2 6 19 12 38 12 l33 0 72 -207z m586 143 c-3 -15 -8 -25 -11 -23 -2 3
-1 17 3 31 3 15 8 25 11 23 2 -3 1 -17 -3 -31z m-20 -70 c-3 -15 -8 -25 -11
-23 -2 3 -1 17 3 31 3 15 8 25 11 23 2 -3 1 -17 -3 -31z m-78 -203 c-8 -15
-15 -25 -16 -21 0 12 23 58 27 54 3 -2 -2 -17 -11 -33z m-3316 -93 c6 -11 8
-20 6 -20 -3 0 -10 9 -16 20 -6 11 -8 20 -6 20 3 0 10 -9 16 -20z m30 -50 c6
-11 8 -20 6 -20 -3 0 -10 9 -16 20 -6 11 -8 20 -6 20 3 0 10 -9 16 -20z m1354
-215 c14 -18 46 -41 71 -51 52 -22 56 -29 18 -39 -65 -16 -111 -53 -141 -116
l-30 -61 -17 52 c-22 67 -63 113 -121 135 l-47 18 48 18 c61 23 120 80 142
137 l17 45 18 -53 c10 -29 29 -67 42 -85z m-1171 20 c11 -16 14 -25 6 -18 -8
7 -30 34 -48 60 -24 36 -26 40 -6 18 14 -16 36 -43 48 -60z m1487 31 c0 -8 12
-24 27 -36 22 -17 25 -23 13 -30 -8 -6 -18 -10 -23 -10 -4 0 -13 -11 -19 -25
l-11 -25 -18 29 c-10 16 -28 32 -40 36 -21 7 -20 8 9 22 17 8 34 24 37 34 7
22 25 26 25 5z m-1305 -96 c33 -33 57 -60 54 -60 -2 0 -31 27 -64 60 -33 33
-57 60 -54 60 2 0 31 -27 64 -60z m165 -147 c0 -7 -78 56 -87 70 -4 6 14 -6
40 -27 26 -21 47 -40 47 -43z m1096 -75 l35 -21 -39 -17 c-27 -12 -45 -30 -59
-59 -20 -41 -21 -41 -27 -15 -11 41 -34 71 -67 84 l-30 13 38 21 c21 12 47 38
58 59 l20 38 18 -41 c11 -26 32 -50 53 -62z m-816 -88 c58 -29 157 -70 220
-91 121 -40 140 -54 26 -19 -37 12 -72 18 -79 14 -6 -4 -9 -4 -5 1 10 11 -170
88 -184 79 -7 -5 -8 -2 -3 6 6 10 4 12 -9 7 -10 -4 -15 -3 -11 3 3 5 -14 19
-37 31 -24 12 -56 31 -72 42 -16 11 -39 21 -50 22 -12 1 -21 6 -21 12 0 6 -11
20 -25 32 -14 12 13 -3 60 -32 47 -30 132 -78 190 -107z m70 -10 c8 -5 11 -10
5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m105 -40 c13 -6
15 -9 5 -9 -8 0 -22 4 -30 9 -18 12 -2 12 25 0z m192 -86 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m46 -11 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12
5 14 0 19 -2 13 -5z m60 -10 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2
18 -5z"/>
<path d="M1770 3916 c-264 -111 -565 -329 -690 -501 -8 -11 16 12 53 52 171
182 407 340 687 461 14 6 21 11 15 11 -5 0 -35 -10 -65 -23z"/>
<path d="M1486 3704 c-123 -80 -248 -187 -343 -295 -34 -37 -3 -12 73 61 71
69 172 154 223 190 118 83 127 89 121 90 -3 0 -36 -21 -74 -46z"/>
<path d="M1024 1590 c-26 -10 -54 -48 -54 -72 0 -16 10 -18 90 -18 87 0 90 1
90 23 -1 29 -20 54 -54 67 -32 12 -43 12 -72 0z"/>
<path d="M3730 809 c-179 -166 -475 -325 -745 -400 -55 -15 -92 -28 -83 -28 9
-1 70 14 135 33 213 61 421 165 594 297 88 67 189 158 174 158 -5 0 -39 -27
-75 -60z"/>
<path d="M2848 373 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2793 363 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2708 353 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M1727 1603 c-65 -102 -87 -140 -87 -147 0 -3 32 -6 70 -6 l70 0 0
110 c0 61 -2 110 -5 110 -3 0 -24 -30 -48 -67z"/>
<path d="M2340 1525 l0 -178 79 5 c92 5 125 22 148 76 21 49 20 160 -1 201
-26 48 -58 64 -147 69 l-79 5 0 -178z"/>
<path d="M2875 1680 c-3 -5 -21 -54 -39 -110 l-34 -100 80 0 c60 0 79 3 76 13
-3 6 -20 56 -37 110 -18 53 -34 97 -36 97 -2 0 -6 -4 -10 -10z"/>
<path d="M3635 1680 c-4 -6 -22 -55 -40 -110 l-34 -100 81 0 c60 0 79 3 76 13
-3 6 -20 56 -37 110 -18 53 -34 97 -36 97 -2 0 -7 -5 -10 -10z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
